<template>
    <div class='role_manage'>
         <a-modal 
            :title="title" 
            :confirm-loading="confirmLoading" 
            @ok="addNew"
            v-model:visible="showDialog">
            <m-dialog-content :formItems="formItems">
                <p>选择角色权限：</p>
                <a-tree
                    checkable
                    checkStrictly
                    multiple
                    :tree-data="treeData"
                    v-model:expandedKeys="expandedKeys"
                    v-model:selectedKeys="selectedKeys"
                    v-model:checkedKeys="checkedKeys"
                    :replaceFields="replaceFields"
                    @select="onSelect"
                    @check="onCheck"/>
            </m-dialog-content>
         </a-modal>
        <div class="role_manage_add">
            <a-button type="primary" @click="add">
                <template v-slot:icon>
                    <PlusCircleOutlined />
                </template>
                添加新角色
            </a-button>
        </div>
        <m-table 
        :buttons="buttons" 
        :columns="columns" 
        :totals="total"
        :currentPage="currentPage"
        :loading="loading"
        @delete="deleteRoles($event)"
        @edit="edit($event)"
        @pageChange="pageChange($event)"
        :data="tableList"></m-table>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { PlusCircleOutlined } from '@ant-design/icons-vue'
// import { menus } from '../../common/common-data'
import { addRole, isValid, roleList, deleteRole, editRole, roleDetail, getMenuTree} from '../../utils/api'
import { showMessage, confirmDialog, geneUniId, formatMenuTree, geneIndex} from '../../utils/util'
import { routes } from '../../router/route'
// import { MENU } from '@/utils/model'
// interface TreeData {
//     treeData: MENU[];
// }
export default {
    name: '',
    components: { PlusCircleOutlined },
    setup() {
         const state= reactive({
             buttons: [
                 {text: '编辑', eventName: 'edit'},
                 {text: '删除', eventName: 'delete'}
             ],
             columns: [
                 {title: '序号',  dataIndex: 'index', key: 'index'},
                 {title: '角色名称',  dataIndex: 'roleName', key: 'roleName'},
                 {title: '角色描述',  dataIndex: 'roleDesc', key: 'roldDesc'},
                 {title: '操作',  dataIndex: 'manage', key: 'name',  slots: {customRender: 'actions'}},
             ],
             title: '添加新角色',
             confirmLoading: false,
             showDialog: false,
             formItems: [
                    {label: '角色名称', value: '', type: 'input'},
                    {label: '描述', value: '', type: 'input'}
            ],
            expandedKeys: [],
            selectedKeys: [],
            checkedKeys: [],
            
            treeArray: [],
            replaceFields: {
                children: 'childMenus',
                key: 'menuId'
            },
            currentPage: 1, //当前页面
            pageSize: 10, // 每页行数
            tableList: [],
            editRoleId: '',
            total: 0,
            loading: true,
            treeData: routes
        })
        // const _state: TreeData = reactive({
        //     treeData: [],
        // })
       
        formatMenuTree(state.treeData)
        console.log(state.treeData)

        // 获取角色列表
        async function loadData() {
            try {
                state.loading = true
                const query = {
                    pageNum: state.currentPage,
                    pageSize: state.pageSize
                }
                const result = await roleList(query)
                if(isValid(result)) {
                    state.tableList = geneUniId(result.data.records) 
                    state.tableList = geneIndex(state.tableList, state.pageSize, state.currentPage)
                    state.total = result.data.total
                }
                state.loading = false
            }  catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        // 获取角色详情
        async function getDetail() {
            try {
                const result = await roleDetail(state.editRoleId)
                if(isValid(result)) {
                    state.checkedKeys = result.data.menuIds
                }
            } catch(e) {
                console.error(e)
            }
        }
         // 确认框确认操作结果
        function confirmResult(msg) {
             loadData()
             showMessage(msg)
             state.showDialog = false
             state.checkedKeys = []
        }
        // 获取菜单树
        async function loadMenuTree() {
            try {
                const result = await getMenuTree()
                if (isValid(result)) {
                    state.treeData = formatMenuTree(result.data.childMenus)
                }
            } catch(e) {
                console.error(e)
            }
        }
        // 获取菜单树
        loadMenuTree()
        // 编辑角色
        function edit(ele) {
            state.editRoleId = ele.roleId
            getDetail()
            state.formItems[0].value = ele.roleName
            state.formItems[1].value = ele.roleDesc
            state.title = '编辑角色'
            state.showDialog = true
        }
        // 添加角色
        function add() {
            state.editRoleId = ''
            state.selectedKeys = []
            state.checkedKeys = []
            state.formItems.forEach(ele => ele.value = '')
            state.showDialog = true
            state.title = '添加新角色'
        }
        // 添加新角色
        async function addNew() {
            try {
                const checkedKey = state.checkedKeys.checked.filter(ele => ele !== '0-0')
                const params = {
                    roleName: state.formItems[0].value,
                    roleDesc: state.formItems[1].value,
                    menuIds: checkedKey.join(','),
                    roleId: '',
                }
                if(state.editRoleId != '') {
                    params.roleId = state.editRoleId
                    const result = await editRole(params)
                    if(result.code == 0) {
                       confirmResult('编辑成功')
                    }
                } else {
                    const result = await addRole(params)
                    if(result.code == 0) {
                        confirmResult('添加角色成功')
                    }
                }
            } catch(e) {
                console.error(e)
            }
        }
        // 删除角色
        async function deleteRoles(data) {
            try {
                const isDelete = await confirmDialog('删除', '是否确认删除角色')
                if(isDelete) {
                    const result = await deleteRole(data.roleId)
                    if(result.code == 0){
                        showMessage('删除成功')
                        loadData()
                    }
                }
            } catch(e) {
                console.error(e)
            }
        }
        // 获取选中角色
        function onSelect(selectedKeys) {
            state.selectedKeys = selectedKeys
            console.log(selectedKeys)
        }
        // 修改选中角色
        function onCheck(selectedKeys) {
            state.checkedKeys = selectedKeys
            console.log(selectedKeys)
        }
        loadData()
        function pageChange(data) {
            state.currentPage = data
            loadData()
        }
        return {
            ...toRefs(state),
            // ...toRefs(_state),
            edit,
            addNew,
            onSelect,
            onCheck,
            deleteRoles,
            add,
            pageChange
        }
    },
}
</script>
<style lang="scss" scoped>
.role_manage {
    position: relative;
    &_add {
        position: absolute;
        width: 200px;
        height: 50px;
        z-index: 3;
        top: -68px;
        right: -54px;
    }
}
</style>